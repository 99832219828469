body {
  font-family: -apple-system,
               BlinkMacSystemFont,
               "Segoe UI",
               Roboto,
               "Helvetica Neue",
               Arial,
               sans-serif,
               "Apple Color Emoji",
               "Segoe UI Emoji",
               "Segoe UI Symbol";
  font-size: 14px;
  margin: 0;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

header {
  padding: 10px;
}

.main-container {
  position: relative;
  background: #fff;
  padding: 20px 10px;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

#header {
  position: relative;
  z-index: 10;
  max-width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 2px 8px #f0f1f2;
  box-shadow: 0 2px 8px #f0f1f2;
}

#footer {
  color: white;
  background-color: #004b85;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 0.9em;
  padding: 15px;
}

#logo {
  overflow: hidden;
  /* color: rgba(0,0,0,.85); */
  font-size: 1.2em;
  font-weight: 500;
  /* font-family: Avenir,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji; */
  line-height: 40px;
  vertical-align: middle;
  display: flex;
  white-space: nowrap;
  text-decoration: none;
}

#nav-container {
  flex-flow: nowrap;
  height: 64px;
}

#user-container {
  line-height: 48px;
  float: right !important;
  margin-right: 20px;
}

#nav {
  height: 100%;
  font-size: 14px;
  /* font-family: Avenir,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji; */
  font-weight: 500;
  border: 0;
  float: right;
  margin-right: 40px;
  line-height: 60px;
}

